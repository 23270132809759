import { createTheme, responsiveFontSizes } from '@mui/material'

import '@fontsource-variable/open-sans'
import '@fontsource-variable/rubik'
import type { LinkProps } from '@mui/material/Link'
import { LinkBehavior } from '@goschool/mui'

const designFont = 'Rubik, Arial, sans-serif'
const bodyFont = 'Open Sans, Arial, sans-serif'


const paletteTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9ad1f0'
    },
    secondary: {
      main: '#f9b000'
    },
    background: {
      default: '#000000',
      paper: '#1d294d'
    }
  }
})

export const oeTheme = responsiveFontSizes(
  createTheme(paletteTheme, {

    typography: {
      fontFamily: bodyFont,
      body1: { fontFamily: bodyFont },
      body2: { fontFamily: bodyFont, fontSize: '0.8rem' },
      h1: { fontFamily: designFont },
      h2: { fontFamily: designFont },
      h3: { fontFamily: designFont },
      h4: { fontFamily: designFont },
      h5: { fontFamily: designFont },
      h6: { fontFamily: designFont },
      subtitle1: { fontFamily: designFont },
      subtitle2: { fontFamily: designFont }
    },

    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior
        } as LinkProps
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior
        }
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            '& .MuiBreadcrumbs-ol': {
              flexWrap: 'nowrap',
              justifyContent: 'flex-start',
              overflow: 'hidden',
              '& .MuiBreadcrumbs-li': {
                flexShrink: 1,
                flexGrow: 0,
                overflow: 'hidden',
                '& .MuiTypography-root': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color:  paletteTheme.palette.primary.main,
                  textDecoration: 'none'
                }
              },
              '& li.MuiBreadcrumbs-separator': {
                color: paletteTheme.palette.primary.dark
              }
            }
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            '& .MuiIconButton-root': {
              color: paletteTheme.palette.primary.main
            }
          }
        }
      }
    }
  }
))
