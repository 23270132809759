import type { AppBarProps } from '@mui/material'
import { AppBar as MuiAppBar, IconButton, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { PageBreadcrumbs, PageLayout } from '@goschool/components'
import { useParams } from 'react-router-dom'
import { useCourseRef } from '@goschool/dao'
import type { PropsWithChildren } from 'react'
import { useCallback, useLayoutEffect, useState } from 'react'

import type { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore'
import type { Course, CourseChat } from '@goschool/model'
import { AuthButton } from '@goschool/auth'

import { CourseChatList } from './components/CourseChatList'
import { ChatContextProvider, ChatMessages, ChatPrompt } from '@goschool/shared/chat'
import { useFirestoreSnapshot } from '@progos/firebase-chat'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import EditIcon from '@mui/icons-material/Edit'
import { GoSchool } from '@goschool/routing'
import { useChats } from './components/useChats'


export function CourseChatPage() {
  const courseRef = useCourseRef(useParams<{
    organizationId: string
    courseId: string
  }>())


  const courseSnapshot = useFirestoreSnapshot(courseRef)

  if (courseSnapshot==null || courseRef==null || !courseSnapshot.exists()) {
    return null
  }

  return <CourseChatLayout courseSnapshot={courseSnapshot} />
}

function courseChatBreadcrumbs(
  courseSnapshot: QueryDocumentSnapshot<Course>,
  selectedChat: DocumentSnapshot<CourseChat> | null | undefined) {
  return [
    {
      title: selectedChat?.data()?.title ?? 'New Chat',
      href: GoSchool.courseChat(courseSnapshot.ref)
    }
  ]
}

function CourseChatLayout({ courseSnapshot }: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down('md'))
  const [isOpen, setOpen] = useState<boolean>(!isNarrow)
  const toggleOpen = useCallback(() => setOpen((open) => !open), [setOpen])

  const sidebarWidth = 300

  useLayoutEffect(() => setOpen(!isNarrow), [isNarrow])

  const handleChatSelect = useCallback(
    () => {
      if (isNarrow) {
        setOpen(false)
      }
    }, [isNarrow])

  const { chats, selectedChat, selectChat, chatManager, createChat } = useChats(courseSnapshot, handleChatSelect)
  const chatSnapshot = useFirestoreSnapshot(selectedChat)

  return <ChatContextProvider chatManager={chatManager} mode="chat">
    <PageLayout fullScreen={true}>
      <AppBar position="static" sidebarWidth={sidebarWidth} isSidebarOpen={isOpen}>
        <Toolbar variant="dense" disableGutters={true}>
          <IconButton edge="start" size="small" onClick={toggleOpen}><ViewListRoundedIcon /></IconButton>
          <IconButton edge="end" size="small" onClick={createChat}><EditIcon /></IconButton>
        </Toolbar>
        <PageBreadcrumbs breadcrumbs={courseChatBreadcrumbs(courseSnapshot, chatSnapshot)} />
        <AuthButton />
      </AppBar>
      <ChatLayout sidebarWidth={sidebarWidth} isSidebarOpen={isOpen}>
        <Sidebar>
          <CourseChatList chats={chats} selectedChat={selectedChat} selectChat={selectChat} />
        </Sidebar>
        <Content>
          <div className="Chat-Messages"><ChatMessages /></div>
          <div className="Chat-Prompt"><ChatPrompt /></div>
        </Content>
      </ChatLayout>
    </PageLayout>
  </ChatContextProvider>
}


function Sidebar({ children }: PropsWithChildren) {
  return <div className="ChatLayout-Sidebar">
    {children}
  </div>
}


function Content({ children }: PropsWithChildren) {
  return <div className="ChatLayout-Content">
    {children}
  </div>
}

interface ChatLayoutProps extends PropsWithChildren {
  sidebarWidth: number;
  isSidebarOpen: boolean;
}

const AppBar = styled(MuiAppBar, {
  name: 'AppBar',
  slot: 'Root',
  shouldForwardProp: (propName) => propName!=='sidebarWidth' && propName!=='isSidebarOpen'
})<ChatLayoutProps & AppBarProps>(
  ({ theme, sidebarWidth, isSidebarOpen }) => ({
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiToolbar-root': {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: isSidebarOpen ? `${sidebarWidth}px`:'auto',
    },
    '& .MuiBreadcrumbs-root': {
      flex: '1 1 0',
      display: 'flex',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden'
    }
  })
)


const ChatLayout = styled(
  'div', {
    name: 'ChatLayout',
    slot: 'Root',
    shouldForwardProp: (propName) => propName!=='sidebarWidth' && propName!=='isSidebarOpen'
  })<ChatLayoutProps>(
  ({ theme, sidebarWidth, isSidebarOpen }) => ({
    flex: '1 0 auto',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    overflow: 'hidden',

    '& .ChatLayout-Sidebar': {
      display: 'flex',
      flegGrow: 0,
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: isSidebarOpen ? `${sidebarWidth}px`:0,

      '& > *': {
        flex: '1 1 0'
      }
    },
    '& .ChatLayout-Content': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0',
      gap: theme.spacing(0),
      backgroundColor: theme.palette.background.default,
      '& > .Chat-Messages': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        flex: '1 1 0',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1)
      },
      '& > .Chat-Prompt': {
        flex: '0 0 200ox',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(2)
      }
    }
  })
)



