import type { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore'
import { addDoc, collection, orderBy, query, serverTimestamp, Timestamp } from 'firebase/firestore'
import type { Course, GoSchoolUserRole, Organization } from '@goschool/model'
import type { SelectChangeEvent } from '@mui/material'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid2 as Grid,
  TextField
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import type { ChangeEvent, FormEvent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { GoSchoolDialog } from '@goschool/mui'
import { typeConverter, useFirestore, useFirestoreResults } from '@progos/firebase-chat'
import { useUserContext } from '@goschool/auth'


interface InvitationFormFields {
  email: string | null;
  name: string | null;
  message: string | null;

  organization: QueryDocumentSnapshot<Organization> | null;
  course: QueryDocumentSnapshot<Course> | null;
  roles: GoSchoolUserRole[];
}


interface FieldErrors {
  email: string | null;
  name: string | null;
  message: string | null;
  roles: string | null;
  expires_in: string | null;
  organization: string | null;
  course: string | null;
}


interface CreateInvitationDialogProps {
  displayed: boolean;
  hide: () => void;
}

export function CreateInvitationDialog({ displayed, hide }: CreateInvitationDialogProps) {
  return <GoSchoolDialog open={displayed} maxWidth="md" fullWidth={true} onClose={hide}>
    <InvitationDialog hide={hide} />
  </GoSchoolDialog>
}

export function InvitationDialog({ hide }: { hide: () => void }) {
  const [fields, setFields] = useState<InvitationFormFields>({
    email: null,
    name: null,
    message: null,
    roles: ['instructor'],
    course: null,
    organization: null
  })
  const { user } = useUserContext()

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({
    email: null,
    name: null,
    message: null,
    roles: null,
    expires_in: null,
    organization: null,
    course: null
  })

  const [submitState, setSubmitState] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle')
  const [error, setError] = useState<string | null>(null)
  const { t } = useTranslation()
  const organizations = useOrganizations()

  const organizationRef = useMemo(
    () => fields.organization?.ref,
    [fields.organization]
  )
  const courses = useCourses(organizationRef)

  const firestore = useFirestore()
  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      setFieldErrors({
        email: null, name: null, message: null, roles: null,
        expires_in: null, organization: null, course: null
      })
      setError(null)
      setSubmitState('submitting')
      try {
        const validation = validateForm(fields, t)
        if (validation!=null) {
          setFieldErrors(validation.fieldErrors)
          setError(validation.formError)
          return
        }

        if (user==null) {
          throw new Error('User not authenticated')
        }

        const invitation = {
          ...convertForm(fields),
          created_by: user.uid
        }
        await addDoc(collection(firestore, 'invitations'), invitation)
        hide()
      } finally {
        setSubmitState('idle')
      }
    }, [fields, firestore, hide, t, user]
  )


  const setField = useCallback(
    (name: keyof InvitationFormFields) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setFields((current) => ({ ...current, [name]: event.target.value }))
      },
    []
  )

  const selectOrganization = useCallback(
    (organization: QueryDocumentSnapshot<Organization> | null) => {
      setFields((current) => ({ ...current, organization, course: null }))
    },
    []
  )

  const selectCourse = useCallback(
    (course: QueryDocumentSnapshot<Course> | null) => {
      setFields((current) => ({ ...current, course }))
    },
    []
  )


  // useEffect(() => {
  //   if (fields.name!=null) {
  //     const message = t('auth:invitation.form.defaultMessage',
  //       {
  //         name: fields.name,
  //         sender: user?.displayName ?? 'GoSchool'
  //       }
  //     )
  //     setFields(current => ({
  //       ...current,
  //       message
  //     }))
  //   }
  // }, [t, fields.name, user?.displayName])


  return <form onSubmit={submit}>
    <DialogTitle><Trans i18nKey="auth:invitation.form.title" /></DialogTitle>
    <DialogContent>
      <OrganizationSelector
        error={fieldErrors.organization}
        value={fields.organization}
        onChange={selectOrganization}
        options={organizations}
        optionLabel={(org) => org?.data().name ?? ''}
      />

      <CourseSelector
        error={fieldErrors.course}
        value={fields.course}
        onChange={selectCourse}
        options={courses}
        optionLabel={(course) => course?.data().title ?? ''}
      />

      <TextField
        margin="normal"
        size="small" fullWidth={true}
        error={fieldErrors.name!=null} helperText={fieldErrors.name}
        label={t('auth:invitation.form.fields.name')} value={fields.name ?? ''}
        onChange={setField('name')} />

      <TextField
        margin="normal" type="email"
        size="small" fullWidth={true}
        error={fieldErrors.email!=null} helperText={fieldErrors.email}
        label={t('auth:invitation.form.fields.email')} value={fields.email ?? ''}
        onChange={setField('email')} />

      {/*<TextField*/}
      {/*  margin="normal" */}
      {/*  size="small" fullWidth={true} multiline={true} rows={4}*/}
      {/*  error={fieldErrors.message!=null} helperText={fieldErrors.message}*/}
      {/*  label={t('auth:invitation.form.fields.message')} value={fields.message ?? ''}*/}
      {/*  onChange={setField('message')} />*/}


      {error!=null && <Grid offset={{ xs: 0, lg: 2 }} size={{ xs: 12, lg: 10 }}>
        <FormHelperText error={true}>{error}</FormHelperText>
      </Grid>}
    </DialogContent>
    <DialogActions>
      <LoadingButton size="small" type="submit" variant="contained" color="primary"
                     loading={submitState==='submitting'}
                     autoFocus={true} onClick={submit}>
        <Trans i18nKey="auth:invitation.form.submit" />
      </LoadingButton>
      <Button variant="outlined" sx={{ textTransform: 'inherit' }} onClick={hide} size="small"><Trans
        i18nKey="auth:invitation.form.cancel" /></Button>
    </DialogActions>
  </form>
}

function useOrganizations() {
  const firestore = useFirestore()
  const collectionRef = useMemo(
    () =>
      query(
        collection(firestore, 'organizations'),
        orderBy('name')
      ).withConverter(typeConverter<Organization>()),
    [firestore]
  )
  return useFirestoreResults(collectionRef)
}

function useCourses(organization: DocumentReference<Organization> | null | undefined) {
  const collectionRef = useMemo(
    () => {
      if (organization!=null) {
        return query(
          collection(organization, 'courses'),
          orderBy('title')
        ).withConverter(typeConverter<Course>())
      }
    },
    [organization]
  )
  return useFirestoreResults(collectionRef)
}

interface FirestoreSnapshotSelectProps<T> {
  error: string | null,
  value: QueryDocumentSnapshot<T> | null,
  onChange: (item: QueryDocumentSnapshot<T> | null) => void,
  options: QueryDocumentSnapshot<T>[] | null | undefined,
  optionLabel: (snapshot: QueryDocumentSnapshot<T> | null | undefined) => string
}

function OrganizationSelector({
                                error, value, onChange, options, optionLabel
                              }: FirestoreSnapshotSelectProps<Organization>) {
  const { t } = useTranslation()

  const selectItem = useCallback(
    ((event: SelectChangeEvent<string | null>) => {
      const path = event.target.value
      const snapshot = options?.find(org => org.ref.path===path) ?? null
      onChange(snapshot)
    }),
    [onChange, options]
  )

  const itemLabel = useCallback(
    (path: string) => {
      const item = options?.find(org => org.ref.path===path)
      return optionLabel(item)
    }, [optionLabel, options])


  return <FormControl
    size="small"
    variant="outlined"
    error={error!=null}
    fullWidth={true}
    margin="normal"
  >
    <InputLabel size="small" htmlFor="organization-select">
      {t('auth:invitation.form.fields.organization')}
    </InputLabel>
    <Select variant="outlined"
      id="organization-select" size="small"
      label={t('auth:invitation.form.fields.organization')}
      value={value?.ref.path ?? ''} onChange={selectItem} renderValue={itemLabel}>
      {options?.map((option) => (
        <MenuItem key={option.ref.path} value={option.ref.path}>
          {optionLabel(option)}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText error={true}>{error}</FormHelperText>
  </FormControl>
}


function CourseSelector({ error, value, onChange, options, optionLabel }: FirestoreSnapshotSelectProps<Course>) {
  const { t } = useTranslation()

  const selectItem = useCallback(
    ((event: SelectChangeEvent<string | null>) => {
      const path = event.target.value
      const snapshot = options?.find(org => org.ref.path===path) ?? null
      onChange(snapshot)
    }),
    [onChange, options]
  )

  const itemLabel = useCallback(
    (path: string) => {
      const item = options?.find(org => org.ref.path===path)
      return optionLabel(item)
    }, [optionLabel, options])


  return <FormControl
    size="small"
    variant="outlined"
    error={error!=null}
    fullWidth={true}
    margin="normal"
  >
    <InputLabel size="small" htmlFor="course-select">
      {t('auth:invitation.form.fields.course')}
    </InputLabel>
    <Select variant="outlined"
      id="course-select" size="small"
      label={t('auth:invitation.form.fields.course')}
      value={value?.ref.path ?? ''} onChange={selectItem} renderValue={itemLabel}>
      {options?.map((option) => (
        <MenuItem key={option.ref.path} value={option.ref.path}>
          {optionLabel(option)}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText error={true}>{error}</FormHelperText>
  </FormControl>
}


function validateForm(fields: InvitationFormFields, t: (key: string) => string): {
  fieldErrors: FieldErrors,
  formError: string | null
} | null {
  const fieldErrors: FieldErrors = {
    email: null,
    name: null,
    message: null,
    roles: null,
    expires_in: null,
    organization: null,
    course: null
  }

  const formError: string | null = null
  let hasErrors = false

  if (fields.email==null || fields.email.trim().length===0) {
    fieldErrors.email = t('auth:invitation.form.errors.noEmail')
    hasErrors = true
  } else if (!isValidEmail(fields.email)) {
    fieldErrors.email = t('auth:invitation.form.errors.invalidEmail')
    hasErrors = true
  }

  if (fields.name==null || fields.name.trim().length===0) {
    fieldErrors.name = t('auth:invitation.form.errors.noName')
    hasErrors = true
  }

  if (fields.organization==null) {
    fieldErrors.organization = t('auth:invitation.form.errors.noOrganization')
    hasErrors = true
  }

  if (fields.roles.length===0) {
    fieldErrors.roles = t('auth:invitation.form.errors.noRoles')
    hasErrors = true
  }

  return hasErrors ? { fieldErrors, formError }:null
}

function convertForm(fields: InvitationFormFields) {
  const millisPerDay = 1000 * 60 * 60 * 24
  const expires_at = Timestamp.fromDate(new Date(Date.now() + 3 * millisPerDay))

  if (fields.email==null || fields.name==null || fields.organization==null) {
    throw new Error('Email and name must be set')
  }

  return {
    email: fields.email,
    name: fields.name,
    message: fields.message,
    created_at: serverTimestamp(),
    expires_at,
    course: fields.course?.ref ?? null,
    organization: fields.organization.ref,
    roles: fields.roles
  }
}


function isValidEmail(email: string):boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const isValid = emailRegex.test(email)
  return isValid
}
